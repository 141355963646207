import Decimal from "decimal.js";
import moment from "moment";
import { MONTHS_OPTIONS, SATOSHIS_TO_BTC } from "../constants";
import {
  BasicInfoDtoOnboardingStatusAdminPanelEnum,
  PatchOpportunityStatusDtoStatusEnum,
  ResTransactionDtoStatusEnum,
  StreamingBasicStatusEnum,
} from "../model/api";

export function checkExhaustive(_x: never): never {
  throw new Error("Exhaustive check failed");
}

export const satoshiToBtc = (input: number | string | Decimal) => new Decimal(input).div(SATOSHIS_TO_BTC).toNumber();
export const btcToSatoshi = (input: number | string | Decimal) => new Decimal(input).mul(SATOSHIS_TO_BTC).toNumber();

const getError = (errors: any, name: string) => {
  return errors?.[name] || "";
};

const reduceHash = (hash: string, reduceNumber: number = 4): string => {
  return `${hash.substring(0, reduceNumber)}...${hash.slice(-reduceNumber)}`;
};

// Comes from app.scss
type StylingStatusClass =
  | "pending-status"
  | "awaiting-status"
  | "confirmed-status"
  | "approved-status"
  | "rejected-status"
  | "fullyonboarded-status"
  | "active-status";

export const getTransactionStatusClass = (status: ResTransactionDtoStatusEnum): StylingStatusClass => {
  switch (status) {
    case ResTransactionDtoStatusEnum.Confirmed:
      return "confirmed-status";
    case ResTransactionDtoStatusEnum.Pending:
      return "pending-status";
    default:
      checkExhaustive(status);
  }
};

export const getOnboardingStatusAdminPanelStatusClass = (
  status: BasicInfoDtoOnboardingStatusAdminPanelEnum
): StylingStatusClass => {
  switch (status) {
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.EntrypointApproved:
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.EntrypointPending:
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.KYCAMLNotFullfilled:
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.KYCAMLPending:
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.KYCAMLApproved:
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.KYMNotFullfilled:
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.KYMPending:
      return "pending-status";
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.Onboarded:
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.KYMApproved:
      return "fullyonboarded-status";
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.EntrypointRejected:
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.KYCAMLRejected:
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.KYMRejected:
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.Suspended:
      return "rejected-status";
    case BasicInfoDtoOnboardingStatusAdminPanelEnum.None:
    case undefined:
      return "confirmed-status";
    default:
      checkExhaustive(status);
  }
};

export const getPatchOpportunityStatusDtoStatusEnumClass = (
  status: PatchOpportunityStatusDtoStatusEnum | undefined
): StylingStatusClass => {
  switch (status) {
    case PatchOpportunityStatusDtoStatusEnum.AwaitingCollateral:
    case PatchOpportunityStatusDtoStatusEnum.AwaitingFunding:
    case PatchOpportunityStatusDtoStatusEnum.Draft:
      return "awaiting-status";
    case PatchOpportunityStatusDtoStatusEnum.Active:
      return "active-status";
    case PatchOpportunityStatusDtoStatusEnum.Defaulted:
    case PatchOpportunityStatusDtoStatusEnum.Cancelled:
      return "rejected-status";
    case PatchOpportunityStatusDtoStatusEnum.Closed:
    case PatchOpportunityStatusDtoStatusEnum.Settled:
    case undefined:
      return "confirmed-status";
    default:
      checkExhaustive(status);
  }
};

export const getStreamingBasicStatusEnumClass = (status: StreamingBasicStatusEnum | undefined): StylingStatusClass => {
  switch (status) {
    case StreamingBasicStatusEnum.AwaitingCollateral:
    case StreamingBasicStatusEnum.AwaitingFunding:
    case StreamingBasicStatusEnum.Draft:
      return "awaiting-status";
    case StreamingBasicStatusEnum.Active:
      return "active-status";
    case StreamingBasicStatusEnum.Defaulted:
    case StreamingBasicStatusEnum.Cancelled:
      return "rejected-status";
    case StreamingBasicStatusEnum.Closed:
    case StreamingBasicStatusEnum.Settled:
    case undefined:
      return "confirmed-status";
    default:
      checkExhaustive(status);
  }
};

export const getOpportunityStatusClass = (status: StreamingBasicStatusEnum | undefined): StylingStatusClass => {
  switch (status) {
    case StreamingBasicStatusEnum.AwaitingCollateral:
    case StreamingBasicStatusEnum.AwaitingFunding:
    case StreamingBasicStatusEnum.Draft:
      return "awaiting-status";
    case StreamingBasicStatusEnum.Active:
      return "active-status";
    case StreamingBasicStatusEnum.Defaulted:
    case StreamingBasicStatusEnum.Cancelled:
      return "rejected-status";
    case StreamingBasicStatusEnum.Closed:
    case StreamingBasicStatusEnum.Settled:
    case undefined:
      return "confirmed-status";
    default:
      checkExhaustive(status);
  }
};

const copyToClipboard = (text: string) => {
  const input = document.createElement("textarea");
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  const result = document.execCommand("copy");
  document.body.removeChild(input);

  return result;
};

const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const amountFormatSatoshisToBTC = (amountSatoshis: Decimal.Value) => {
  if (!amountSatoshis) return amountFormat(0, "BTC");
  return amountFormat(new Decimal(amountSatoshis).div(SATOSHIS_TO_BTC).toNumber(), "BTC");
};

const amountFormat = (
  amount: number | bigint,
  currency: string = "USD",
  minimumFractionDigits: number | undefined = undefined,
  maximumFractionDigits: number = 8
) => {
  let num = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    notation: "compact",
    minimumFractionDigits: minimumFractionDigits ?? (currency === "BTC" ? undefined : 2),
    maximumFractionDigits: currency === "BTC" ? maximumFractionDigits : undefined,
  }).format(amount);

  if (currency === "BTC") {
    num = num.replace("BTC", ""); // TODO Check is it possible to replace this with superscript tag
  }

  return num;
};

const diffDaysAndHours = (date: string) => {
  const date1: Date = new Date();
  const date2: Date = new Date(date);

  const diffInMs = Math.abs(date2.getTime() - date1.getTime());
  const diffInHours = diffInMs / (1000 * 60 * 60);
  const days = Math.floor(diffInHours / 24);
  const hours = Math.floor(diffInHours % 24);

  return { days, hours };
};

const roundToSignificantDigitsDiv1000 = (value: Decimal.Value, significantDigits: number = 2) => {
  if (!value) return 0;
  return roundToSignificantDigits(new Decimal(value).div(1000).toNumber(), significantDigits);
};

const roundToSignificantDigits = (value: number, significantDigits: number = 2) => {
  if (value === 0) return 0;

  if (value < 1) {
    const exponent = Math.floor(Math.log10(Math.abs(value))) + 1 - significantDigits;
    const factor = Math.pow(10, exponent);
    const roundedNumber = Math.round(value / factor) * factor;

    return roundedNumber.toFixed(Math.max(0, -exponent));
  } else {
    const roundedNum = parseFloat(value.toFixed(significantDigits));

    return parseFloat(roundedNum.toFixed(significantDigits));
  }
};

const getChartFilterMonths = (isCurrentYear: boolean) => {
  const currentMonth = moment().format("MMMM");
  let monthsOptions = [];

  if (!isCurrentYear) {
    monthsOptions = MONTHS_OPTIONS;
  } else {
    const currentMonthIndex = MONTHS_OPTIONS.findIndex((option) => option.value === currentMonth);
    monthsOptions = MONTHS_OPTIONS.slice(0, currentMonthIndex + 1);
  }

  return monthsOptions;
};

const calculatePriceBtcThSecoundDays = ({
  maxAmountOfLiquidity,
  fullHashrateThPerSecond,
  durationOfAgreementDays,
}: {
  maxAmountOfLiquidity: number | undefined;
  fullHashrateThPerSecond: number | undefined;
  durationOfAgreementDays: number | undefined;
}) => {
  if (!maxAmountOfLiquidity || !fullHashrateThPerSecond || !durationOfAgreementDays) return 0;
  const maxAmountOfLiquidityDec = new Decimal(maxAmountOfLiquidity).div(SATOSHIS_TO_BTC);
  const fullHashratePhPerSecondDec = new Decimal(fullHashrateThPerSecond).div(1000);
  return maxAmountOfLiquidityDec.div(fullHashratePhPerSecondDec).div(durationOfAgreementDays).toNumber();
};

const downloadFile = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = "test.csv";
  link.style.display = "none";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const DLD_POWER_OF_ATTORNEY = [
  import.meta.env.VITE_BASE_BUCKET_HOST,
  import.meta.env.VITE_CDN_BUCKET,
  import.meta.env.VITE_DLD_POWER_OF_ATTORNEY_TEMPLATE,
].join("/");

export function normalizeUrl(url: string) {
  url = url.trim();
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  }
  return "https://" + url;
}

export function generateMailtoLink(email: string, subject: string, body: string): string {
  return `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
}

const siPrefixes: readonly string[] = [
  "y",
  "z",
  "a",
  "f",
  "p",
  "n",
  "μ",
  "m",
  "",
  "k",
  "M",
  "G",
  "T",
  "P",
  "E",
  "Z",
  "Y",
];

const SI_PREFIXES_CENTER_INDEX = siPrefixes.indexOf("");

export const getSiPrefixedNumber = (number: number): { baseNumber: string; prefix: string } => {
  if (number === 0) return { baseNumber: number.toString(), prefix: "" };
  const EXP_STEP_SIZE = 3;
  const base = Math.floor(Math.log10(Math.abs(number)));
  const siBase = (base < 0 ? Math.ceil : Math.floor)(base / EXP_STEP_SIZE);
  const prefix = siPrefixes[siBase + SI_PREFIXES_CENTER_INDEX];

  // return number as-is if no prefix is available
  if (siBase === 0) return { baseNumber: number.toString(), prefix: "" };

  // We're left with a number which needs to be devided by the power of 10e[base]
  const baseNumber = parseFloat((number / Math.pow(10, siBase * EXP_STEP_SIZE)).toFixed(2));
  return { baseNumber: baseNumber.toString(), prefix };
};

export {
  amountFormat,
  amountFormatSatoshisToBTC,
  calculatePriceBtcThSecoundDays,
  copyToClipboard,
  diffDaysAndHours,
  downloadFile,
  getChartFilterMonths,
  getError,
  reduceHash,
  roundToSignificantDigits,
  roundToSignificantDigitsDiv1000,
  toBase64,
};
