import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Alert, Box, Button, Chip, CircularProgress, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { MouseEvent, SyntheticEvent, useState } from "react";
import { ContractDto, GroupDto } from "../../../app/model/api";
import {
  useMutationAddContract,
  useMutationAddGroup,
  useMutationDeleteContract,
  useMutationDeleteGroup,
  useMutationUpdateContract,
  useMutationUpdateGroupDefaultPool,
} from "../../../app/query/useMutationAdmin";
import { useGetMiningProxyContracts, useGetMiningProxyGroups } from "../../../app/query/useQueryGetAdmin";
import { AddGroupModal } from "./components/AddGroupModal";
import { ContractFormData, ContractModal } from "./components/ContractModal";
import { ContractStatus } from "./components/ContractStatus";
import { DeleteConfirmationDialog } from "./components/DeleteConfirmationDialog";
import { EditPoolModal } from "./components/EditPoolModal";
import { GroupHashrate, GroupStatus, GroupWorkers } from "./components/GroupComponents";
import { HashrateChartModal } from "./components/HashrateChartModal";
import { LabelValue } from "./components/LabelValue";

const formatPoolUrl = (url: string, user?: string, password?: string): string => {
  if (!user && !password) return url;
  const credentials = `${user || ""}${password ? ":" + password : ""}`;
  return `${credentials}@${url}`;
};

export const AdminMiningProxyPage = () => {
  const { data: groups, isLoading: isLoadingGroups, error: groupsError } = useGetMiningProxyGroups();
  const { data: contractsByGroup, isLoading: isLoadingContracts, error: contractsError } = useGetMiningProxyContracts();
  const updateDefaultPool = useMutationUpdateGroupDefaultPool();
  const addGroup = useMutationAddGroup();
  const addContract = useMutationAddContract();
  const updateContract = useMutationUpdateContract();
  const deleteGroup = useMutationDeleteGroup();
  const deleteContract = useMutationDeleteContract();
  const [editingGroup, setEditingGroup] = useState<GroupDto | null>(null);
  const [addingGroupTo, setAddingGroupTo] = useState<GroupDto | null>(null);
  const [addingContractTo, setAddingContractTo] = useState<GroupDto | null>(null);
  const [editingContract, setEditingContract] = useState<ContractDto | null>(null);
  const [deletingGroup, setDeletingGroup] = useState<GroupDto | null>(null);
  const [deletingContract, setDeletingContract] = useState<ContractDto | null>(null);
  const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [showingHashrateChartFor, setShowingHashrateChartFor] = useState<{ uuid: string; title: string } | null>(null);

  if (isLoadingGroups || isLoadingContracts) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (groupsError || contractsError) {
    return (
      <Box p={2}>
        <Typography color="error">Error loading mining proxy data</Typography>
      </Box>
    );
  }

  const handleEditClick = (e: MouseEvent, group: GroupDto) => {
    e.stopPropagation();
    setEditingGroup(group);
  };

  const handleSaveDefaultPool = async (defaultPool: string) => {
    if (!editingGroup) return;

    await updateDefaultPool.mutateAsync({
      uuid: editingGroup.uuid,
      defaultPool,
    });
    setEditingGroup(null);
  };

  const handleExpandedItemsChange = (event: SyntheticEvent, itemIds: string[]) => {
    setExpandedItems(itemIds);
  };

  const handleAddGroup = async (name: string, defaultPool: string) => {
    await addGroup.mutateAsync({
      name,
      defaultPool,
      parentUUID: addingGroupTo?.uuid,
    });
    if (addingGroupTo?.uuid) {
      setExpandedItems((prev) => [...new Set([...prev, addingGroupTo.uuid])]);
    }
    setAddingGroupTo(null);
    setIsAddingNewGroup(false);
  };

  const handleAddContract = async (formData: ContractFormData) => {
    if (!addingContractTo) return;
    await addContract.mutateAsync({
      groupUUID: addingContractTo.uuid,
      ...formData,
    });
    setExpandedItems((prev) => [...new Set([...prev, addingContractTo.uuid])]);
    setAddingContractTo(null);
  };

  const handleEditContract = async (formData: ContractFormData) => {
    if (!editingContract) return;
    await updateContract.mutateAsync({
      contractUUID: editingContract.uuid,
      ...formData,
    });
    setEditingContract(null);
  };

  const handleDeleteGroup = async (e: MouseEvent, group: GroupDto) => {
    e.stopPropagation();
    setDeletingGroup(group);
  };

  const handleDeleteContract = async (e: MouseEvent, contract: ContractDto) => {
    e.stopPropagation();
    setDeletingContract(contract);
  };

  const handleConfirmDeleteGroup = async () => {
    if (!deletingGroup) return;
    await deleteGroup.mutateAsync({ uuid: deletingGroup.uuid });
    setDeletingGroup(null);
  };

  const handleConfirmDeleteContract = async () => {
    if (!deletingContract) return;
    await deleteContract.mutateAsync({ contractUUID: deletingContract.uuid });
    setDeletingContract(null);
  };

  const renderGroup = (group: GroupDto) => {
    const groupContracts = contractsByGroup?.contracts[group.uuid] || [];
    const hasChildren = group.members?.length > 0 || groupContracts.length > 0;

    return (
      <TreeItem
        sx={{
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
          mb: 0.5,
        }}
        key={group.uuid}
        itemId={group.uuid}
        label={
          <Box height="100%">
            <Box display="flex" justifyContent="space-between" alignItems="stretch" height="100%">
              <Box>
                <Box display="flex" alignItems="center" gap={1} mb={0.5}>
                  <Tooltip title={group.name}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        width: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {group.name}
                    </Typography>
                  </Tooltip>
                  <Box sx={{ width: 100, height: 24 }}>
                    <GroupStatus uuid={group.uuid} />
                  </Box>
                  <Box sx={{ width: 100, height: 24 }}>
                    <GroupWorkers uuid={group.uuid} />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <LabelValue label="Proxy Address" value={`${group.endpoint}:${group.port}`} />
                  <LabelValue label="Default Pool" value={group.defaultPool} />
                  <LabelValue label="Hashrate" value={<GroupHashrate uuid={group.uuid} />} />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box display="flex" gap={1} justifyContent="flex-end">
                  <Tooltip title="View Hashrate History">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowingHashrateChartFor({ uuid: group.uuid, title: group.name });
                      }}
                      color="primary"
                    >
                      <ShowChartIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton size="small" onClick={(e) => handleEditClick(e, group)} color="primary">
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={hasChildren ? "Delete all sub-groups and contracts first" : "Delete"}>
                    <span>
                      <IconButton
                        size="small"
                        onClick={(e) => handleDeleteGroup(e, group)}
                        color="error"
                        disabled={hasChildren}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
                <Box display="flex" gap={1}>
                  <Button
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAddingGroupTo(group);
                    }}
                  >
                    Subgroup
                  </Button>
                  <Button
                    size="small"
                    startIcon={<PostAddIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAddingContractTo(group);
                    }}
                  >
                    Contract
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        }
      >
        {groupContracts.map((contract: ContractDto) => (
          <TreeItem
            key={contract.uuid}
            itemId={`contract-${contract.uuid}`}
            sx={{
              "& .MuiTreeItem-content": {
                borderRadius: 1,
                backgroundColor: "rgba(25, 118, 210, 0.08)",
                margin: "4px 0",
                padding: 1,
                border: "1px solid rgba(25, 118, 210, 0.2)",
                "&:hover": {
                  backgroundColor: "rgba(25, 118, 210, 0.12)",
                },
              },
              mb: 0.5,
            }}
            label={
              <Box>
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                  <Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Chip
                        icon={<DescriptionIcon />}
                        label="Contract"
                        size="small"
                        color="primary"
                        variant="outlined"
                        sx={{ pl: 0.5, mb: 0.5, width: 100 }}
                      />
                      <ContractStatus uuid={contract.uuid} />
                    </Box>
                    <Box display="flex" flexDirection="column" gap={0.5}>
                      <LabelValue
                        label="Pool"
                        value={formatPoolUrl(contract.miningPoolURL, contract.miningPoolUser, contract.miningPoolPass)}
                      />
                      <LabelValue label="Hashrate" value={`${contract.assignedHashrate} H/s`} />
                      <LabelValue label="Worker ID Mode" value={contract.workernameOption} />
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Tooltip title="View Hashrate History">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowingHashrateChartFor({ uuid: contract.uuid, title: `Contract ${contract.uuid}` });
                        }}
                        color="primary"
                      >
                        <ShowChartIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingContract(contract);
                        }}
                        color="primary"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton size="small" onClick={(e) => handleDeleteContract(e, contract)} color="error">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            }
          />
        ))}
        {group.members?.map((subGroup) => renderGroup(subGroup))}
      </TreeItem>
    );
  };

  return (
    <Paper sx={{ p: 2, maxWidth: 1200, mx: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Mining Proxy Admin
      </Typography>
      <Alert severity="error" sx={{ mb: 2 }}>
        The proxy API has a known issue fetching status and hashrate for sub-groups. The proxy team is working on a fix.
        Top-level groups are unaffected.
      </Alert>
      <SimpleTreeView
        expandedItems={expandedItems}
        onExpandedItemsChange={(event, itemIds) => handleExpandedItemsChange(event, itemIds)}
        itemChildrenIndentation={50}
      >
        {groups?.map((group) => renderGroup(group))}
      </SimpleTreeView>
      <Box mt={2} display="flex" justifyContent="center">
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => setIsAddingNewGroup(true)}>
          Add New Group
        </Button>
      </Box>
      {editingGroup && (
        <EditPoolModal
          open={true}
          onClose={() => setEditingGroup(null)}
          group={editingGroup}
          onSave={handleSaveDefaultPool}
        />
      )}
      {(addingGroupTo || isAddingNewGroup) && (
        <AddGroupModal
          open={true}
          onClose={() => {
            setAddingGroupTo(null);
            setIsAddingNewGroup(false);
          }}
          parentGroup={addingGroupTo || undefined}
          onSave={handleAddGroup}
        />
      )}
      {addingContractTo && (
        <ContractModal
          open={true}
          onClose={() => setAddingContractTo(null)}
          group={addingContractTo}
          onSave={handleAddContract}
        />
      )}
      {editingContract && (
        <ContractModal
          open={true}
          onClose={() => setEditingContract(null)}
          contract={editingContract}
          onSave={handleEditContract}
        />
      )}
      {deletingGroup && (
        <DeleteConfirmationDialog
          open={true}
          onClose={() => setDeletingGroup(null)}
          onConfirm={handleConfirmDeleteGroup}
          title="Delete Group"
          message={`Are you sure you want to delete the group "${deletingGroup.name}"? This action cannot be undone.`}
        />
      )}
      {deletingContract && (
        <DeleteConfirmationDialog
          open={true}
          onClose={() => setDeletingContract(null)}
          onConfirm={handleConfirmDeleteContract}
          title="Delete Contract"
          message={"Are you sure you want to delete this contract? This action cannot be undone."}
        />
      )}
      {showingHashrateChartFor && (
        <HashrateChartModal
          open={true}
          onClose={() => setShowingHashrateChartFor(null)}
          title={showingHashrateChartFor.title}
          uuid={showingHashrateChartFor.uuid}
        />
      )}
    </Paper>
  );
};
