import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PointSymbolProps, ResponsiveLine, Serie, SliceTooltipProps } from "@nivo/line";
import { format, subDays } from "date-fns";
import { useMemo, useState } from "react";
import {
  HashrateDataPoint,
  HashrateHistoryResponseDtoGranularityEnum as HashrateGranularity,
  HashrateHistoryResponseDto,
} from "../../../../app/model/api";
import { useGetMiningProxyHashrateHistory } from "../../../../app/query/useQueryGetAdmin";
import { findBestHashrateUnit, formatHashrate } from "../../../../utils/hashrate";

interface HashrateChartModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  uuid: string;
}

const DEFAULT_DAYS = 30;
const TARGET_DATA_POINTS = 50; // Target number of data points to display

interface TimeRange {
  label: string;
  days: number;
}

const TIME_RANGES: TimeRange[] = [
  { label: "1D", days: 1 },
  { label: "7D", days: 7 },
  { label: "30D", days: 30 },
  { label: "90D", days: 90 },
];

interface ViewportState {
  start: Date;
  end: Date;
  isDragging: boolean;
  dragStart: { x: number; y: number } | null;
}

const CustomAxisTick = ({
  x,
  y,
  value,
  granularity,
  data,
}: {
  x: number;
  y: number;
  value: Date;
  granularity: HashrateGranularity;
  data: HashrateDataPoint[];
}) => {
  const date = format(value, granularity === HashrateGranularity.Daily ? "MMM dd" : "MMM dd HH:mm");

  // For daily data, check if there are missing hours
  if (granularity === HashrateGranularity.Daily) {
    const dayData = data.find(
      (point: HashrateDataPoint) => format(new Date(point.timestamp), "yyyy-MM-dd") === format(value, "yyyy-MM-dd")
    );

    if (!dayData) return null;

    const hasMissingData = dayData.missing || (dayData.missingHourCount && dayData.missingHourCount > 0);
    const tooltipText = dayData.missing
      ? "No data available for this day"
      : `${dayData.missingHourCount} missing hours`;

    return (
      <g transform={`translate(${x},${y}) rotate(-45)`}>
        {hasMissingData ? (
          <Tooltip title={tooltipText}>
            <text
              textAnchor="end"
              dominantBaseline="text-before-edge"
              style={{
                fontSize: 10,
                fill: "#ed6c02",
              }}
            >
              {date}
            </text>
          </Tooltip>
        ) : (
          <text
            textAnchor="end"
            dominantBaseline="text-before-edge"
            style={{
              fontSize: 10,
              fill: "currentColor",
            }}
          >
            {date}
          </text>
        )}
      </g>
    );
  }

  // For hourly data, find if this hour is missing
  const hourData = data.find(
    (point: HashrateDataPoint) => format(new Date(point.timestamp), "yyyy-MM-dd HH") === format(value, "yyyy-MM-dd HH")
  );
  const isHourMissing = !hourData || hourData.missing;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        transform="rotate(-45)"
        textAnchor="end"
        dominantBaseline="text-before-edge"
        style={{
          fontSize: 10,
          fill: isHourMissing ? "#ed6c02" : "currentColor", // MUI warning color
        }}
      >
        {date}
      </text>
    </g>
  );
};

const sampleData = (data: HashrateDataPoint[], targetPoints: number): HashrateDataPoint[] => {
  if (!data || data.length <= targetPoints) return data;

  const step = Math.ceil(data.length / targetPoints);
  const sampledData: HashrateDataPoint[] = [];

  for (let i = 0; i < data.length; i += step) {
    // For each step, find min, max, and average values in the window
    const window = data.slice(i, Math.min(i + step, data.length));
    const validPoints = window.filter((point) => !point.missing);

    if (validPoints.length === 0) {
      sampledData.push(window[0]); // Keep missing data points for visual continuity
      continue;
    }

    // Find the point with the median timestamp in the window
    const medianIndex = Math.floor(window.length / 2);
    const basePoint = window[medianIndex];

    // Calculate aggregated values for the window
    const minHashrate = Math.min(...validPoints.map((p) => p.minHashrate));
    const maxHashrate = Math.max(...validPoints.map((p) => p.maxHashrate));
    const avgHashrate = validPoints.reduce((sum, p) => sum + p.averageHashrate, 0) / validPoints.length;

    sampledData.push({
      ...basePoint,
      minHashrate,
      maxHashrate,
      averageHashrate: avgHashrate,
      // If any point in the window has missing hours, sum them
      missingHourCount: window.reduce((sum, p) => sum + (p.missingHourCount || 0), 0),
    });
  }

  return sampledData;
};

export const HashrateChartModal = ({ open, onClose, title, uuid }: HashrateChartModalProps) => {
  const [dateRange, setDateRange] = useState(() => {
    const end = new Date();
    const start = subDays(end, DEFAULT_DAYS);
    return { start, end };
  });

  const handleTimeRangeClick = (days: number) => {
    const end = new Date();
    const start = subDays(end, days - 1);
    setDateRange({ start, end });
  };

  const { data, isLoading } = useGetMiningProxyHashrateHistory(
    uuid,
    format(dateRange.start, "yyyy-MM-dd"),
    format(dateRange.end, "yyyy-MM-dd")
  ) as { data: HashrateHistoryResponseDto | undefined; isLoading: boolean };

  const sampledData = useMemo(() => {
    if (!data?.data) return [];
    return sampleData(data.data, TARGET_DATA_POINTS);
  }, [data?.data]);

  const allHashrates = useMemo(() => {
    if (!sampledData) return [];
    return sampledData.flatMap((point: HashrateDataPoint) =>
      point.missing ? [] : [point.averageHashrate, point.minHashrate, point.maxHashrate]
    );
  }, [sampledData]);

  const hashrateUnit = useMemo(() => findBestHashrateUnit(allHashrates), [allHashrates]);

  const chartData: Serie[] = sampledData
    ? [
        {
          id: "Average Hashrate",
          data: sampledData.map((point: HashrateDataPoint) => ({
            x: new Date(point.timestamp),
            y: point.missing ? null : point.averageHashrate / hashrateUnit.value,
          })),
        },
        {
          id: "Min Hashrate",
          data: sampledData.map((point: HashrateDataPoint) => ({
            x: new Date(point.timestamp),
            y:
              point.missing || (point.missingHourCount && point.missingHourCount > 0)
                ? null
                : point.minHashrate / hashrateUnit.value,
          })),
        },
        {
          id: "Max Hashrate",
          data: sampledData.map((point: HashrateDataPoint) => ({
            x: new Date(point.timestamp),
            y: point.missing ? null : point.maxHashrate / hashrateUnit.value,
          })),
        },
      ]
    : [];

  const renderTooltip = ({ slice }: SliceTooltipProps) => {
    const point = sampledData?.find(
      (p: HashrateDataPoint) => new Date(p.timestamp).getTime() === (slice.points[0].data.x as Date).getTime()
    );

    if (!point) return null;

    if (point.missing) {
      return (
        <Box
          sx={{
            background: "white",
            padding: 1,
            border: "1px solid #ccc",
          }}
        >
          <Typography variant="body2">{format(new Date(point.timestamp), "MMM dd yyyy HH:mm")}</Typography>
          <Typography variant="body2" color="error">
            No data available
          </Typography>
        </Box>
      );
    }

    const avgPoint = slice.points.find((p) => p.serieId === "Average Hashrate");
    const minPoint = slice.points.find((p) => p.serieId === "Min Hashrate");
    const maxPoint = slice.points.find((p) => p.serieId === "Max Hashrate");

    return (
      <Box
        sx={{
          background: "white",
          padding: 1,
          border: "1px solid #ccc",
        }}
      >
        <Typography variant="body2">
          {format(
            new Date(point.timestamp),
            data?.granularity === HashrateGranularity.Daily ? "MMM dd yyyy" : "MMM dd yyyy HH:mm"
          )}
        </Typography>
        {avgPoint && (
          <Typography variant="body2">Average: {formatHashrate(point.averageHashrate, 0).formatted}</Typography>
        )}
        {minPoint && maxPoint && (
          <Typography variant="body2">
            Range: {formatHashrate(point.minHashrate, 0).formatted} - {formatHashrate(point.maxHashrate, 0).formatted}
          </Typography>
        )}
        {data?.granularity === HashrateGranularity.Daily && point.missingHourCount && point.missingHourCount > 0 ? (
          <Typography variant="body2" color="error">
            Missing hours: {point.missingHourCount}
          </Typography>
        ) : null}
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ pt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                value={[dateRange.start, dateRange.end]}
                onChange={(newValue) => {
                  if (newValue[0] && newValue[1]) {
                    setDateRange({ start: newValue[0], end: newValue[1] });
                  }
                }}
                slotProps={{
                  textField: { size: "small" },
                }}
              />
            </LocalizationProvider>
            <ButtonGroup size="small" aria-label="time range" sx={{ pt: 1 }}>
              {TIME_RANGES.map((range) => (
                <Button
                  key={range.label}
                  onClick={() => handleTimeRangeClick(range.days)}
                  variant={
                    dateRange.end.getTime() - dateRange.start.getTime() === (range.days - 1) * 24 * 60 * 60 * 1000
                      ? "contained"
                      : "outlined"
                  }
                >
                  {range.label}
                </Button>
              ))}
            </ButtonGroup>
          </Stack>
          <Box sx={{ height: 400 }}>
            {isLoading ? (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            ) : (
              <ResponsiveLine
                data={chartData}
                margin={{ top: 20, right: 20, bottom: 60, left: 80 }}
                xScale={{
                  type: "time",
                  format: "native",
                  precision: data?.granularity === HashrateGranularity.Hourly ? "hour" : "day",
                }}
                yScale={{
                  type: "linear",
                  min: 0,
                  max: Math.max(...allHashrates.map((h) => h / hashrateUnit.value)) * 1.1,
                }}
                axisBottom={{
                  renderTick: (tick) => (
                    <CustomAxisTick
                      {...tick}
                      granularity={data?.granularity || HashrateGranularity.Daily}
                      data={data?.data || []}
                    />
                  ),
                }}
                axisLeft={{
                  legend: `Hashrate (${hashrateUnit.symbol}H/s)`,
                  legendOffset: -60,
                  legendPosition: "middle",
                }}
                enablePoints={true}
                pointSymbol={({ datum, color: defaultColor }: Readonly<PointSymbolProps>) => {
                  let color = defaultColor;
                  const point = datum;
                  const pointDate = point.x;

                  if (pointDate instanceof Date) {
                    const dataPoint = data?.data?.find(
                      (p: HashrateDataPoint) =>
                        format(new Date(p.timestamp), "yyyy-MM-dd") === format(pointDate, "yyyy-MM-dd")
                    );

                    const shouldBeOrange = dataPoint?.missingHourCount && dataPoint.missingHourCount > 0;
                    if (shouldBeOrange) color = "#ed6c02";
                  }
                  return <circle cx="0" cy="0" r={3} fill={color} />;
                }}
                enableArea={false}
                enableSlices="x"
                sliceTooltip={renderTooltip}
                colors={["#2196f3", "#90caf9", "#1976d2"]}
                curve="monotoneX"
                animate={false}
              />
            )}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
