import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import PaidIcon from "@mui/icons-material/Paid";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Decimal from "decimal.js";
import { sortBy } from "lodash";
import { ReactElement } from "react";
import { v4 } from "uuid";
import { satoshiToBtc } from "../../../../app/helper/utils";
import { ResOpportunityDetailsV2Dto, ResTimelineTransactionDto, ResWalletAddressDto } from "../../../../app/model/api";

export const isInEndState = (opportunityState: {
  status:
    | "Awaiting Collateral"
    | "Awaiting Funding"
    | "Draft"
    | "Active"
    | "Defaulted"
    | "Cancelled"
    | "Settled"
    | "Closed";
}) => ["Closed", "Defaulted", "Cancelled", "Settled"].includes(opportunityState.status);

export type TimelineItemDefinition = {
  id: string;
  date: Date;
  icon?: ReactElement;
  description: string;
  amount?: string;
  details?: ReactElement;
  from?: ReactElement;
  to?: ReactElement;
  txStatus?: string;
};

export const formatSatoshi = (input: number | string | Decimal) => `${+satoshiToBtc(input).toFixed(7)} BTC`;

export const buildTimeline = (
  opportunity: ResOpportunityDetailsV2Dto,
  transactions: ResTimelineTransactionDto[],
  addresses: ResWalletAddressDto[]
): TimelineItemDefinition[] => {
  const result: TimelineItemDefinition[] = [];

  result.push({ id: v4(), date: new Date(opportunity.creationDate), description: "Opportunity created" });

  if (opportunity.publishDate) {
    result.push({ id: v4(), date: new Date(opportunity.publishDate), description: "Opportunity published." });
  }

  if (opportunity.activeFromDate) {
    result.push({
      id: v4(),
      date: new Date(opportunity.activeFromDate),
      description: "Opportunity has become active.",
    });
  }

  if (opportunity.cancellationDate) {
    result.push({
      id: v4(),
      date: new Date(opportunity.cancellationDate),
      description: "Opportunity has been cancelled.",
    });
  }

  if (opportunity.fullyFundedDate) {
    result.push({
      id: v4(),
      date: new Date(opportunity.fullyFundedDate),
      description: "Opportunity is now fully funded.",
    });
  }
  if (opportunity.settlementDate) {
    result.push({
      id: v4(),
      date: new Date(opportunity.settlementDate),
      description: "Opportunity has been settled.",
    });
  }

  for (const tx of transactions) {
    const fromAddress = addresses.find((x) => x.address === tx.fromAddress);
    const toAddress = addresses.find((x) => x.address === tx.toAddress);
    result.push({
      id: tx.id,
      icon:
        tx.kind === "Reward Delivery" || tx.kind === "Reward Distribution" ? (
          <EmojiEventsIcon color="success" />
        ) : tx.kind === "Collateral Deposit" || tx.kind === "Opportunity Funding" ? (
          <AccountBalanceIcon color="info" />
        ) : tx.kind === "Collateral Release" || tx.kind === "Excess Reward Release" ? (
          <LowPriorityIcon color="info" />
        ) : tx.kind === "Collateral Liquidation" ? (
          <RemoveCircleOutlineIcon color="warning" />
        ) : (
          <PaidIcon />
        ),
      date: new Date(tx.blockTimestamp ?? tx.createdAt),
      description: `${tx.kind} transaction`,
      amount: formatSatoshi(tx.amountSatoshi),
      from: <>{fromAddress?.name ?? tx.fromAddress}</>,
      to: <>{toAddress?.name ?? tx.toAddress}</>,
      txStatus: tx.approvalStatus,
    });
  }

  result.push({
    id: v4(),
    date: new Date(),
    description: `Current state: ${opportunity.status}.`,
  });

  return sortBy(result, (x) => x.date);
};
