const HASHRATE_UNITS = [
  { value: 1e21, symbol: "Z", name: "Zetta" },
  { value: 1e18, symbol: "E", name: "Exa" },
  { value: 1e15, symbol: "P", name: "Peta" },
  { value: 1e12, symbol: "T", name: "Tera" },
  { value: 1e9, symbol: "G", name: "Giga" },
  { value: 1e6, symbol: "M", name: "Mega" },
  { value: 1e3, symbol: "K", name: "Kilo" },
  { value: 1, symbol: "", name: "" },
] as const;

export interface HashrateUnit {
  value: number;
  formatted: string;
  unit: string;
  fullUnit: string;
}

export function formatHashrate(hashrateInHashes: number, precision = 2): HashrateUnit {
  const unit =
    HASHRATE_UNITS.find(({ value }) => hashrateInHashes >= value) || HASHRATE_UNITS[HASHRATE_UNITS.length - 1];

  const value = hashrateInHashes / unit.value;
  const formatted = `${value.toFixed(precision)} ${unit.symbol}H/s`;
  const unitString = `${unit.symbol}H/s`;
  const fullUnit = `${unit.name}hash/s`;

  return {
    value,
    formatted,
    unit: unitString,
    fullUnit,
  };
}

export function findBestHashrateUnit(values: number[]): (typeof HASHRATE_UNITS)[number] {
  if (!values.length) return HASHRATE_UNITS[HASHRATE_UNITS.length - 1];
  const maxHashrate = Math.max(...values);
  return HASHRATE_UNITS.find(({ value }) => maxHashrate >= value) || HASHRATE_UNITS[HASHRATE_UNITS.length - 1];
}
